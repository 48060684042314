import vars from "../config/vars";

export const getImageUrlFromDocument = (document: any, size?: string) => {
  if (!document) return "";
  return `${vars.apiUrl}/documents/${document.id}/image${size ? `?size=${size}` : ""}`;
};

export const getImageUrlFromDocumentId = (documentId?: string, isPublic?: boolean, size?: string) => {
  if (!documentId) return "";
  return `${vars.apiUrl}/${isPublic ? "public-" : ""}documents/${documentId}/image${size ? `?size=${size}` : ""}`;
};
