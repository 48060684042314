import { useState, useEffect, useCallback } from "react";

type UseSimultaneousKeyPressCallback = () => void;

const useSimultaneousKeyPress = (targetKeys: string[], callback: UseSimultaneousKeyPressCallback) => {
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set());

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    setPressedKeys((prev) => {
      const newSet = new Set(prev);
      newSet.add(event.key.toLowerCase());
      return newSet;
    });
  }, []);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    setPressedKeys((prev) => {
      const newSet = new Set(prev);
      newSet.delete(event.key.toLowerCase());
      return newSet;
    });
  }, []);

  useEffect(() => {
    const allKeysPressed =
      targetKeys.every((key) => pressedKeys.has(key.toLowerCase())) && pressedKeys.size === targetKeys.length;

    if (allKeysPressed) {
      callback();
    }
  }, [pressedKeys, targetKeys, callback]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return pressedKeys;
};

export default useSimultaneousKeyPress;
