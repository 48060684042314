import { AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const subdomain = window.location.hostname.split(".")[0];

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_KEYCLOAK_URL,
  client_id: process.env.REACT_APP_KEYCLOAK_FORCE_CLIENT_ID
    ? process.env.REACT_APP_KEYCLOAK_FORCE_CLIENT_ID
    : process.env.REACT_APP_KEYCLOAK_CLIENT_ID_PREFIX + subdomain,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_FORCE_REDIRECT_URL
    ? process.env.REACT_APP_KEYCLOAK_FORCE_REDIRECT_URL
    : `https://${subdomain}.speammaster.de`,
  post_logout_redirect_uri: process.env.REACT_APP_KEYCLOAK_FORCE_POST_LOGOUT_URL
    ? process.env.REACT_APP_KEYCLOAK_FORCE_POST_LOGOUT_URL
    : `https://${subdomain}.speammaster.de`,
  scope: "openid profile roles email",
  loadUserInfo: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  checkSessionIntervalInSeconds: 60,
};

export default oidcConfig;
