import { useQuery } from "@tanstack/react-query";
import {
  LocationService,
  EmployeeService,
  ManufacturerService,
  ContactService,
  CustomerService,
  NumberCircleService,
  AttributeGroupService,
  AttributeService,
  SpeamboxService,
  SpeamboxFamilyService,
  SpeamboxInstallationGuideService,
  SpeamboxInstallationGuideStepService,
  SpeamboxModelService,
  SpeamboxInputOutputBlockService,
  SpeamboxInputOutputPinService,
  SpeamboxInputOutputPinStateService,
  SpeamcoreSpeamboxService,
} from "src/client";
import { useTranslation } from "react-i18next";

export const useGetModuleIdTitle = () => {
  const { module_type, id } = getModuleTypeAndId();
  const { t } = useTranslation("id_routes");

  const queryFn = () => {
    switch (module_type) {
      case "locations":
        return LocationService.getLocation({ id });
      case "number-circles":
        return NumberCircleService.getNumberCircle({ id });
      case "employees":
        return EmployeeService.getEmployee({ id });
      case "manufacturers":
        return ManufacturerService.getManufacturer({ id });
      case "contacts":
        return ContactService.getContact({ id });
      case "customers":
        return CustomerService.getCustomer({ id });
      case "attribute-groups":
        return AttributeGroupService.getAttributeGroup({ id });
      case "attributes":
        return AttributeService.getAttribute({ id });
      case "speamboxes":
        return SpeamboxService.getSpeambox({ id });
      case "speambox-models":
        return SpeamboxModelService.getSpeamboxModel({ id });
      case "speambox-families":
        return SpeamboxFamilyService.getSpeamboxFamily({ id });
      case "speambox-installation-guides":
        return SpeamboxInstallationGuideService.getSpeamboxInstallationGuide({ id });
      case "speambox-installation-guide-steps":
        return SpeamboxInstallationGuideStepService.getSpeamboxInstallationGuideStep({ id });
      case "speambox-input-output-blocks":
        return SpeamboxInputOutputBlockService.getSpeamboxInputOutputBlock({ id });
      case "speambox-input-output-pins":
        return SpeamboxInputOutputPinService.getSpeamboxInputOutputPin({ id });
      case "speambox-input-output-pin-states":
        return SpeamboxInputOutputPinStateService.getSpeamboxInputOutputPinState({ id });
      case "speamcore-speamboxes":
        return SpeamcoreSpeamboxService.getSpeamcoreSpeambox({ id });
      default:
        return Promise.reject("Invalid module_type");
    }
  };

  const { data, isLoading, error } = useQuery([module_type, id], queryFn);

  if (isLoading) return t("loading");

  if (error) {
    console.error("Error fetching data:", error);
    return "";
  }

  switch (module_type) {
    case "locations":
    case "employees":
    case "manufacturers":
    case "contacts":
    case "customers":
    case "number-circles":
    case "speambox-models":
    case "speambox-families":
    case "speambox-installation-guides":
    case "speambox-installation-guide-steps":
    case "speambox-input-output-blocks":
    case "speambox-input-output-pins":
    case "speambox-input-output-pin-states":
      //@ts-ignore
      return t(module_type) + (data?.name || "");

    case "speamboxes":
      //@ts-ignore
      return t(module_type) + (data?.serialNumber || "");

    case "speamcore-speamboxes":
      //@ts-ignore
      return t(module_type) + (data?.speambox?.serialNumber || "");

    case "attribute-groups":
    case "attributes":
      return (
        t(module_type) +
        //@ts-ignore
        (data?.systemAttribute && data?.translationKey ? t(`common:${data?.translationKey}`) : data?.name || "")
      );

    default:
      return "";
  }
};

function getModuleTypeAndId() {
  const url = window.location.pathname;
  const segments = url.split("/");

  let moduleTypeIndex = segments.findIndex((segment) => segment !== "");
  let moduleType = segments[moduleTypeIndex];
  let id = segments[moduleTypeIndex + 1];

  if (id === undefined) {
    id = moduleType;
    moduleType = "";
  }

  return { module_type: moduleType, id: id };
}
