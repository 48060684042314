import { lazy } from "react";

// Pages
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));

// Layouts
const InvalidLayout = lazy(() => import("./layouts/InvalidLayout"));

// Employee Pages
const EmployeeListPage = lazy(() => import("./pages/Employee/pages/EmployeeListPage"));
const EmployeeDetailsPage = lazy(() => import("./pages/Employee/pages/EmployeeDetailsPage"));

// Location Pages
const LocationListPage = lazy(() => import("./pages/Location/pages/LocationListPage"));
const LocationDashboardPage = lazy(() => import("./pages/Location/pages/LocationDashboardPage"));
const LocationDetailsPage = lazy(() => import("./pages/Location/pages/LocationDetailsPage"));
const LocationContactListPage = lazy(() => import("./pages/Location/pages/LocationContactListPage"));
const LocationContactDetailsPage = lazy(() => import("./pages/Location/pages/LocationContactDetailsPage"));

// Manufacturer Pages
const ManufacturerListPage = lazy(() => import("./pages/Manufacturer/pages/ManufacturerListPage"));
const ManufacturerDetailsPage = lazy(() => import("./pages/Manufacturer/pages/ManufacturerDetailsPage"));

// Customer Pages
const CustomerListPage = lazy(() => import("./pages/Customer/pages/CustomerListPage"));
const CustomerDashboardPage = lazy(() => import("./pages/Customer/pages/CustomerDashboardPage"));
const CustomerDetailsPage = lazy(() => import("./pages/Customer/pages/CustomerDetailsPage"));
const CustomerContactListPage = lazy(() => import("./pages/Customer/pages/CustomerContactListPage"));
const CustomerContactDetailsPage = lazy(() => import("./pages/Customer/pages/CustomerContactDetailsPage"));
const CustomerLocationListPage = lazy(() => import("./pages/Customer/pages/CustomerLocationListPage"));

// Customization Pages
const CustomizationThemePage = lazy(() => import("./pages/Customization/pages/CustomizationThemePage"));
const CustomizationImagePage = lazy(() => import("./pages/Customization/pages/CustomizationImagePage"));

const SettingsPage = lazy(() => import("./pages/Settings/pages/SettingsPage"));

// NumberCircle Pages
const NumberCircleListPage = lazy(() => import("./pages/NumberCircle/pages/NumberCircleListPage"));
const NumberCircleDetailsPage = lazy(() => import("./pages/NumberCircle/pages/NumberCircleDetailsPage"));

// AttributeGroup Pages
const AttributeGroupListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeGroupListPage"));
const AttributeGroupDetailsPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeGroupDetailsPage"));

// Grouped Attribute Pages
const GroupedAttributeListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeListPage"));
const GroupedAttributeDetailsPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeDetailsPage"));

// Grouped AttributeModel Pages
const GroupedAttributeModelListPage = lazy(() => import("./pages/AttributeGroup/pages/AttributeModelListPage"));

// Attribute Pages
const AttributeListPage = lazy(() => import("./pages/Attribute/pages/AttributeListPage"));
const AttributeDetailsPage = lazy(() => import("./pages/Attribute/pages/AttributeDetailsPage"));

// AttributeModel Pages
const AttributeModelListPage = lazy(() => import("./pages/Attribute/pages/AttributeModelListPage"));

// Speambox Pages
const SpeamboxListPage = lazy(() => import("./pages/Speambox/pages/SpeamboxListPage"));
const SpeamboxDashboardPage = lazy(() => import("./pages/Speambox/pages/SpeamboxDashboardPage"));
const SpeamboxDetailsPage = lazy(() => import("./pages/Speambox/pages/SpeamboxDetailsPage"));
const SpeamboxNetworkListPage = lazy(() => import("./pages/Speambox/pages/SpeamboxNetworkListPage"));

// Speambox Family Pages
const SpeamboxFamilyListPage = lazy(() => import("./pages/SpeamboxFamily/pages/SpeamboxFamilyListPage"));
const SpeamboxFamilyDetailsPage = lazy(() => import("./pages/SpeamboxFamily/pages/SpeamboxFamilyDetailsPage"));
const SpeamboxManufacturerModelListPage = lazy(
  () => import("./pages/SpeamboxFamily/pages/SpeamboxManufacturerModelListPage")
);
const SpeamboxManufacturerModelDetailsPage = lazy(
  () => import("./pages/SpeamboxFamily/pages/SpeamboxManufacturerModelDetailsPage")
);
const SpeamboxManufacturerModelProtocolListPage = lazy(
  () => import("./pages/SpeamboxFamily/pages/SpeamboxManufacturerModelProtocolListPage")
);

// Speambox Installation Guide Pages
const SpeamboxInstallationGuideListPage = lazy(
  () => import("./pages/SpeamboxInstallationGuide/pages/SpeamboxInstallationGuideListPage")
);
const SpeamboxInstallationGuideDetailsPage = lazy(
  () => import("./pages/SpeamboxInstallationGuide/pages/SpeamboxInstallationGuideDetailsPage")
);
const SpeamboxInstallationGuideParentListPage = lazy(
  () => import("./pages/SpeamboxInstallationGuide/pages/SpeamboxInstallationGuideParentListPage")
);

// Speambox Installation Guide Step Pages
const SpeamboxInstallationGuideStepListPage = lazy(
  () => import("./pages/SpeamboxInstallationGuideStep/pages/SpeamboxInstallationGuideStepListPage")
);
const SpeamboxInstallationGuideStepDetailsPage = lazy(
  () => import("./pages/SpeamboxInstallationGuideStep/pages/SpeamboxInstallationGuideStepDetailsPage")
);

// Speambox Model Pages
const SpeamboxModelListPage = lazy(() => import("./pages/SpeamboxModel/pages/SpeamboxModelListPage"));
const SpeamboxModelDetailsPage = lazy(() => import("./pages/SpeamboxModel/pages/SpeamboxModelDetailsPage"));
const SpeamboxModelInputOutputListPage = lazy(
  () => import("./pages/SpeamboxModel/pages/SpeamboxModelInputOutputListPage")
);

// Speambox Input Output Block Pages
const SpeamboxInputOutputBlockListPage = lazy(
  () => import("./pages/SpeamboxInputOutputBlock/pages/SpeamboxInputOutputBlockListPage")
);
const SpeamboxInputOutputBlockDetailsPage = lazy(
  () => import("./pages/SpeamboxInputOutputBlock/pages/SpeamboxInputOutputBlockDetailsPage")
);

// Speambox Input Output Pin Pages
const SpeamboxInputOutputPinListPage = lazy(
  () => import("./pages/SpeamboxInputOutputPin/pages/SpeamboxInputOutputPinListPage")
);
const SpeamboxInputOutputPinDetailsPage = lazy(
  () => import("./pages/SpeamboxInputOutputPin/pages/SpeamboxInputOutputPinDetailsPage")
);
const SpeamboxInputOutputPinStateParentListPage = lazy(
  () => import("./pages/SpeamboxInputOutputPin/pages/SpeamboxInputOutputPinStateParentListPage")
);

// Speambox Input Output Pin State Pages
const SpeamboxInputOutputPinStateListPage = lazy(
  () => import("./pages/SpeamboxInputOutputPinState/pages/SpeamboxInputOutputPinStateListPage")
);
const SpeamboxInputOutputPinStateDetailsPage = lazy(
  () => import("./pages/SpeamboxInputOutputPinState/pages/SpeamboxInputOutputPinStateDetailsPage")
);

// Speamcore Speambox Pages
const SpeamcoreSpeamboxListPage = lazy(() => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxListPage"));
const SpeamcoreSpeamboxDashboardPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxDashboardPage")
);
const SpeamcoreSpeamboxDetailsPage = lazy(() => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxDetailsPage"));
const SpeamcoreSpeamboxNotificationListPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxNotificationListPage")
);
const SpeamcoreSpeamboxNotificationDetailsPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxNotificationDetailsPage")
);
const SpeamcoreSpeamboxMQTTListPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxMQTTListPage")
);
const SpeamcoreSpeamboxPanelDetailsPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxPanelDetailsPage")
);
const SpeamcoreSpeamboxLogListPage = lazy(() => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxLogListPage"));

const SpeamcoreSpeamboxInputOutputListPage = lazy(
  () => import("./pages/SpeamcoreSpeambox/pages/SpeamcoreSpeamboxInputOutputListPage")
);

export const routePaths = {
  home: "/",
  login: "/login",
  locations: "/locations",
  locationDashboard: (locationId: string) => `/locations/${locationId}/dashboard`,
  locationDetails: (locationId: string) => `/locations/${locationId}`,
  locationContactList: (locationId: string) => `/locations/${locationId}/contacts`,
  locationContactDetailsPage: (locationId: string, contactId: string) =>
    `/locations/${locationId}/contacts/${contactId}`,
  employees: "/employees",
  employeeDetails: (employeeId: string) => `/employees/${employeeId}`,
  manufacturers: "/manufacturers",
  manufacturerDetails: (manufacturerId: string) => `/manufacturers/${manufacturerId}`,
  customers: "/customers",
  customerDashboard: (customerId: string) => `/customers/${customerId}/dashboard`,
  customerDetails: (customerId: string) => `/customers/${customerId}`,
  customerContactList: (customerId: string) => `/customers/${customerId}/contacts`,
  customerContactDetailsPage: (customerId: string, contactId: string) =>
    `/customers/${customerId}/contacts/${contactId}`,
  customerLocationList: (customerId: string) => `/customers/${customerId}/locations`,
  customerLocationDashboardPage: (customerId: string, locationId: string) =>
    `/customers/${customerId}/locations/${locationId}/dashboard`,
  customerLocationDetailsPage: (customerId: string, locationId: string) =>
    `/customers/${customerId}/locations/${locationId}`,
  customerLocationContactList: (customerId: string, locationId: string) =>
    `/customers/${customerId}/locations/${locationId}/contacts`,
  customerLocationContactDetailsPage: (customerId: string, locationId: string, contactId: string) =>
    `/customers/${customerId}/locations/${locationId}/contacts/${contactId}`,
  customizationTheme: "/customization/theme",
  customizationImage: "/customization/images",
  settings: "/settings",
  numberCircles: "/number-circles",
  numberCircleDetails: (numberCircleId: string) => `/number-circles/${numberCircleId}`,
  attributeGroups: "/attribute-groups",
  attributeGroupDetails: (attributeGroupId: string) => `/attribute-groups/${attributeGroupId}`,
  attributeGroupAttributeList: (attributeGroupId: string) => `/attribute-groups/${attributeGroupId}/attributes`,
  attributeGroupAttributeDetails: (attributeGroupId: string, attributeId: string) =>
    `/attribute-groups/${attributeGroupId}/attributes/${attributeId}`,
  attributeGroupAttributeModelList: (attributeGroupId: string, attributeId: string) =>
    `/attribute-groups/${attributeGroupId}/attributes/${attributeId}/models`,
  attributes: "/attributes",
  attributeDetails: (attributeId: string) => `/attributes/${attributeId}`,
  attributeModelList: (attributeId: string) => `/attributes/${attributeId}/models`,
  speamboxes: "/speamboxes",
  speamboxDashboard: (speamboxId: string) => `/speamboxes/${speamboxId}/dashboard`,
  speamboxDetails: (speamboxId: string) => `/speamboxes/${speamboxId}`,
  speamboxNetworkList: (speamboxId: string) => `/speamboxes/${speamboxId}/networks`,
  speamboxFamilies: "/speambox-families",
  speamboxFamilyDetails: (speamboxFamilyId: string) => `/speambox-families/${speamboxFamilyId}`,
  speamboxManufacturerModelList: (speamboxFamilyId: string) => `/speambox-families/${speamboxFamilyId}/models`,
  speamboxManufacturerModelDetails: (speamboxFamilyId: string, speamboxManufacturerModelId: string) =>
    `/speambox-families/${speamboxFamilyId}/models/${speamboxManufacturerModelId}`,
  speamboxManufacturerModelProtocolList: (speamboxFamilyId: string, speamboxManufacturerModelId: string) =>
    `/speambox-families/${speamboxFamilyId}/models/${speamboxManufacturerModelId}/protocols`,
  speamboxInstallationGuides: "/speambox-installation-guides",
  speamboxInstallationGuideDetails: (speamboxInstallationGuideId: string) =>
    `/speambox-installation-guides/${speamboxInstallationGuideId}`,
  speamboxInstallationGuideParentList: (speamboxInstallationGuideId: string) =>
    `/speambox-installation-guides/${speamboxInstallationGuideId}/steps`,
  speamboxInstallationGuideSteps: "/speambox-installation-guide-steps",
  speamboxInstallationGuideStepDetails: (speamboxInstallationGuideStepId: string) =>
    `/speambox-installation-guide-steps/${speamboxInstallationGuideStepId}`,
  speamboxModels: "/speambox-models",
  speamboxModelDetails: (speamboxModelId: string) => `/speambox-models/${speamboxModelId}`,
  speamboxModelInputOutputList: (speamboxModelId: string) => `/speambox-models/${speamboxModelId}/input-outputs`,
  speamboxInputOutputBlocks: "/speambox-input-output-blocks",
  speamboxInputOutputBlockDetails: (speamboxInputOutputBlockId: string) =>
    `/speambox-input-output-blocks/${speamboxInputOutputBlockId}`,
  speamboxInputOutputPins: "/speambox-input-output-pins",
  speamboxInputOutputPinDetails: (speamboxInputOutputPinId: string) =>
    `/speambox-input-output-pins/${speamboxInputOutputPinId}`,
  speamboxInputOutputPinStateParentList: (speamboxInputOutputPinId: string) =>
    `/speambox-input-output-pins/${speamboxInputOutputPinId}/states`,
  speamboxInputOutputPinStates: "/speambox-input-output-pin-states",
  speamboxInputOutputPinStateDetails: (speamboxInputOutputPinStateId: string) =>
    `/speambox-input-output-pin-states/${speamboxInputOutputPinStateId}`,
  speamcoreSpeamboxes: "/speamcore-speamboxes",
  speamcoreSpeamboxDashboard: (speamcoreSpeamboxId: string) => `/speamcore-speamboxes/${speamcoreSpeamboxId}/dashboard`,
  speamcoreSpeamboxDetails: (speamcoreSpeamboxId: string) => `/speamcore-speamboxes/${speamcoreSpeamboxId}`,
  speamcoreSpeamboxNotificationList: (speamcoreSpeamboxId: string) =>
    `/speamcore-speamboxes/${speamcoreSpeamboxId}/notifications`,
  speamcoreSpeamboxNotificationDetailsPage: (speamcoreSpeamboxId: string, notificationId: string) =>
    `/speamcore-speamboxes/${speamcoreSpeamboxId}/notifications/${notificationId}`,
  speamcoreSpeamboxMQTTList: (speamcoreSpeamboxId: string) => `/speamcore-speamboxes/${speamcoreSpeamboxId}/mqtt`,
  speamcoreSpeamboxPanelDetails: (speamcoreSpeamboxId: string) => `/speamcore-speamboxes/${speamcoreSpeamboxId}/panel`,
  speamcoreSpeamboxLogList: (speamcoreSpeamboxId: string) => `/speamcore-speamboxes/${speamcoreSpeamboxId}/logs`,
  speamcoreSpeamboxInputOutputList: (speamcoreSpeamboxId: string) =>
    `/speamcore-speamboxes/${speamcoreSpeamboxId}/input-outputs`,
};

const publicRoutes = [
  { path: routePaths.login, component: Login, layout: Login },
  { path: "*", component: Home, layout: InvalidLayout },
];

const privateRoutes = [
  { path: "/", component: Home },
  {
    path: routePaths.locations,
    component: LocationListPage,
    requiredAbility: [
      ["view", "FE_Location"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.locationDashboard(":id"),
    component: LocationDashboardPage,
    requiredAbility: [
      ["view", "FE_Location"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.locationDetails(":id"),
    component: LocationDetailsPage,
    requiredAbility: [
      ["view", "FE_Location"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.locationContactList(":id"),
    component: LocationContactListPage,
    requiredAbility: [
      ["view", "FE_Location"],
      ["view", "Location"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.locationContactDetailsPage(":id", ":contactId"),
    component: LocationContactDetailsPage,
    requiredAbility: [
      ["view", "FE_Location"],
      ["view", "Location"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.employees,
    component: EmployeeListPage,
    requiredAbility: [
      ["view", "FE_Employee"],
      ["view", "Employee"],
    ],
  },
  {
    path: routePaths.employeeDetails(":id"),
    component: EmployeeDetailsPage,
    requiredAbility: [
      ["view", "FE_Employee"],
      ["view", "Employee"],
    ],
  },
  {
    path: routePaths.manufacturers,
    component: ManufacturerListPage,
    requiredAbility: [
      ["view", "FE_Manufacturer"],
      ["view", "Manufacturer"],
    ],
  },
  {
    path: routePaths.manufacturerDetails(":id"),
    component: ManufacturerDetailsPage,
    requiredAbility: [
      ["view", "FE_Manufacturer"],
      ["view", "Manufacturer"],
    ],
  },
  {
    path: routePaths.customers,
    component: CustomerListPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
    ],
  },
  {
    path: routePaths.customerDashboard(":id"),
    component: CustomerDashboardPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
    ],
  },
  {
    path: routePaths.customerDetails(":id"),
    component: CustomerDetailsPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
    ],
  },
  {
    path: routePaths.customerContactList(":id"),
    component: CustomerContactListPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.customerContactDetailsPage(":id", ":contactId"),
    component: CustomerContactDetailsPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.customerLocationList(":id"),
    component: CustomerLocationListPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.customerLocationDashboardPage(":customerId", ":id"),
    component: LocationDashboardPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.customerLocationDetailsPage(":customerId", ":id"),
    component: LocationDetailsPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Location"],
    ],
  },
  {
    path: routePaths.customerLocationContactList(":customerId", ":id"),
    component: LocationContactListPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.customerLocationContactDetailsPage(":customerId", ":id", ":contactId"),
    component: LocationContactDetailsPage,
    requiredAbility: [
      ["view", "FE_Customer"],
      ["view", "Customer"],
      ["view", "Contact"],
    ],
  },
  {
    path: routePaths.customizationTheme,
    component: CustomizationThemePage,
    requiredAbility: [
      ["view", "FE_Customization"],
      ["view", "Customization"],
      ["view", "PublicAttribute"],
    ],
  },
  {
    path: routePaths.customizationImage,
    component: CustomizationImagePage,
    requiredAbility: [
      ["view", "FE_Customization"],
      ["view", "Customization"],
      ["view", "PublicAttribute"],
    ],
  },
  {
    path: routePaths.settings,
    component: SettingsPage,
    requiredAbility: [
      ["view", "FE_Settings"],
      ["view", "Settings"],
    ],
  },
  {
    path: routePaths.numberCircles,
    component: NumberCircleListPage,
    requiredAbility: [
      ["view", "FE_NumberCircle"],
      ["view", "NumberCircle"],
    ],
  },
  {
    path: routePaths.numberCircleDetails(":id"),
    component: NumberCircleDetailsPage,
    requiredAbility: [
      ["view", "FE_NumberCircle"],
      ["view", "NumberCircle"],
    ],
  },
  {
    path: routePaths.attributeGroups,
    component: AttributeGroupListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
    ],
  },
  {
    path: routePaths.attributeGroupDetails(":id"),
    component: AttributeGroupDetailsPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeList(":id"),
    component: GroupedAttributeListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeDetails(":id", ":attributeId"),
    component: GroupedAttributeDetailsPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeGroupAttributeModelList(":id", ":attributeId"),
    component: GroupedAttributeModelListPage,
    requiredAbility: [
      ["view", "FE_AttributeGroup"],
      ["view", "AttributeGroup"],
      ["view", "Attribute"],
      ["view", "AttributeModel"],
    ],
  },
  {
    path: routePaths.attributes,
    component: AttributeListPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeDetails(":id"),
    component: AttributeDetailsPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
    ],
  },
  {
    path: routePaths.attributeModelList(":id"),
    component: AttributeModelListPage,
    requiredAbility: [
      ["view", "FE_Attribute"],
      ["view", "Attribute"],
      ["view", "AttributeModel"],
    ],
  },
  {
    path: routePaths.speamboxes,
    component: SpeamboxListPage,
    requiredAbility: [
      ["view", "FE_Speambox"],
      ["view", "Speambox"],
    ],
  },
  {
    path: routePaths.speamboxDashboard(":id"),
    component: SpeamboxDashboardPage,
    requiredAbility: [
      ["view", "FE_Speambox"],
      ["view", "Speambox"],
    ],
  },
  {
    path: routePaths.speamboxDetails(":id"),
    component: SpeamboxDetailsPage,
    requiredAbility: [
      ["view", "FE_Speambox"],
      ["view", "Speambox"],
    ],
  },
  {
    path: routePaths.speamboxNetworkList(":id"),
    component: SpeamboxNetworkListPage,
    requiredAbility: [
      ["view", "FE_Speambox"],
      ["view", "Speambox"],
      ["view", "SpeamboxNetwork"],
    ],
  },
  {
    path: routePaths.speamboxFamilies,
    component: SpeamboxFamilyListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxFamily"],
      ["view", "SpeamboxFamily"],
    ],
  },
  {
    path: routePaths.speamboxFamilyDetails(":id"),
    component: SpeamboxFamilyDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxFamily"],
      ["view", "SpeamboxFamily"],
    ],
  },
  {
    path: routePaths.speamboxManufacturerModelList(":id"),
    component: SpeamboxManufacturerModelListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxFamily"],
      ["view", "SpeamboxFamily"],
      ["view", "SpeamboxManufacturerModel"],
    ],
  },
  {
    path: routePaths.speamboxManufacturerModelDetails(":id", ":speamboxManufacturerModelId"),
    component: SpeamboxManufacturerModelDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxFamily"],
      ["view", "SpeamboxFamily"],
      ["view", "SpeamboxManufacturerModel"],
    ],
  },
  {
    path: routePaths.speamboxManufacturerModelProtocolList(":id", ":speamboxManufacturerModelId"),
    component: SpeamboxManufacturerModelProtocolListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxFamily"],
      ["view", "SpeamboxFamily"],
      ["view", "SpeamboxManufacturerModel"],
      ["view", "SpeamboxManufacturerModelProtocol"],
    ],
  },
  {
    path: routePaths.speamboxInstallationGuides,
    component: SpeamboxInstallationGuideListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInstallationGuide"],
      ["view", "SpeamboxInstallationGuide"],
    ],
  },
  {
    path: routePaths.speamboxInstallationGuideDetails(":id"),
    component: SpeamboxInstallationGuideDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInstallationGuide"],
      ["view", "SpeamboxInstallationGuide"],
    ],
  },
  {
    path: routePaths.speamboxInstallationGuideParentList(":id"),
    component: SpeamboxInstallationGuideParentListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInstallationGuide"],
      ["view", "SpeamboxInstallationGuide"],
      ["view", "SpeamboxInstallationGuideParent"],
    ],
  },
  {
    path: routePaths.speamboxInstallationGuideSteps,
    component: SpeamboxInstallationGuideStepListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInstallationGuideStep"],
      ["view", "SpeamboxInstallationGuideStep"],
    ],
  },
  {
    path: routePaths.speamboxInstallationGuideStepDetails(":id"),
    component: SpeamboxInstallationGuideStepDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInstallationGuideStep"],
      ["view", "SpeamboxInstallationGuideStep"],
    ],
  },
  {
    path: routePaths.speamboxModels,
    component: SpeamboxModelListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxModel"],
      ["view", "SpeamboxModel"],
    ],
  },
  {
    path: routePaths.speamboxModelDetails(":id"),
    component: SpeamboxModelDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxModel"],
      ["view", "SpeamboxModel"],
    ],
  },
  {
    path: routePaths.speamboxModelInputOutputList(":id"),
    component: SpeamboxModelInputOutputListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxModel"],
      ["view", "SpeamboxModel"],
      ["view", "SpeamboxInputOutput"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputBlocks,
    component: SpeamboxInputOutputBlockListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputBlock"],
      ["view", "SpeamboxInputOutputBlock"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputBlockDetails(":id"),
    component: SpeamboxInputOutputBlockDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputBlock"],
      ["view", "SpeamboxInputOutputBlock"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputPins,
    component: SpeamboxInputOutputPinListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputPin"],
      ["view", "SpeamboxInputOutputPin"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputPinDetails(":id"),
    component: SpeamboxInputOutputPinDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputPin"],
      ["view", "SpeamboxInputOutputPin"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputPinStateParentList(":id"),
    component: SpeamboxInputOutputPinStateParentListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputPin"],
      ["view", "SpeamboxInputOutputPin"],
      ["view", "SpeamboxInputOutputPinState"],
      ["view", "SpeamboxInputOutputPinStateParent"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputPinStates,
    component: SpeamboxInputOutputPinStateListPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputPinState"],
      ["view", "SpeamboxInputOutputPinState"],
    ],
  },
  {
    path: routePaths.speamboxInputOutputPinStateDetails(":id"),
    component: SpeamboxInputOutputPinStateDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamboxInputOutputPinState"],
      ["view", "SpeamboxInputOutputPinState"],
    ],
  },
  /*Temporary SpeamcoreSpeambox */
  {
    path: routePaths.speamcoreSpeamboxes,
    component: SpeamcoreSpeamboxListPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxDashboard(":id"),
    component: SpeamcoreSpeamboxDashboardPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxDetails(":id"),
    component: SpeamcoreSpeamboxDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxNotificationList(":id"),
    component: SpeamcoreSpeamboxNotificationListPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxNotificationDetailsPage(":id", ":notificationId"),
    component: SpeamcoreSpeamboxNotificationDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeamboxNotification"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxMQTTList(":id"),
    component: SpeamcoreSpeamboxMQTTListPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxLogList(":id"),
    component: SpeamcoreSpeamboxLogListPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },

  {
    path: routePaths.speamcoreSpeamboxInputOutputList(":id"),
    component: SpeamcoreSpeamboxInputOutputListPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
  {
    path: routePaths.speamcoreSpeamboxPanelDetails(":id"),
    component: SpeamcoreSpeamboxPanelDetailsPage,
    requiredAbility: [
      ["view", "FE_SpeamcoreSpeambox"],
      ["view", "SpeamcoreSpeambox"],
    ],
  },
];

export { publicRoutes, privateRoutes };
