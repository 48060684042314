import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { EmployeeService, OpenAPI } from "../client";
import { usePulse } from "../hooks/usePulse";
import { useAuth } from "react-oidc-context";
import { getUserAbility } from "../config/auth/casl";
import { AbilityContext } from "../contexts/AbilityContext";
import { useStore } from "../zustand/store";

// @ts-ignore
export default function SecurityLayout({ children }) {
  // regularly fetch common data
  usePulse();

  // get auth state from keycloak (managed by react oidc context)
  const auth = useAuth();
  const setAccessToken = useStore((state) => state.setAccessToken);

  // @ts-ignore
  const [ability, setAbility] = useState(getUserAbility(auth?.user?.profile?.realm_access?.roles || []));
  const [requestSelf, setRequestSelf] = useState(false);

  useEffect(() => {
    if (
      // @ts-ignore
      auth?.user?.profile?.realm_access?.roles &&
      // @ts-ignore
      Array.isArray(auth?.user?.profile?.realm_access?.roles) &&
      // @ts-ignore
      auth?.user?.profile?.realm_access?.roles.length > 0
    ) {
      // @ts-ignore
      setAbility(getUserAbility(auth?.user?.profile?.realm_access?.roles));
    }

    // @ts-ignore
  }, [auth?.user]);

  useEffect(() => {
    OpenAPI.TOKEN = auth?.user?.access_token;
    setAccessToken(auth?.user?.access_token);
    OpenAPI.WITH_CREDENTIALS = true;

    EmployeeService.getSelf()
      .then(() => {
        //request so user is beeing created in the backend
        setRequestSelf(true);
      })
      .catch(() => {
        //set request on true because user for example failed with no access permission, later on check for example status code instead of just setting it to true
        setRequestSelf(true);
      });

    //eslint-disable-next-line
  }, [auth?.user?.access_token]);

  OpenAPI.TOKEN = auth?.user?.access_token;
  setAccessToken(auth?.user?.access_token);
  OpenAPI.WITH_CREDENTIALS = true;

  // render children if authenticated, else show login button
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <></>; //<div>Signing you in...</div>;
    case "signoutRedirect":
      return <></>; //<div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <></>; //<div>Loading...</div>;
  }

  if (auth.error) {
    console.log(auth);
    return <div>Authentication error {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    if (requestSelf === false) {
      return <></>;
    }

    if (
      //@ts-ignore
      !(auth?.user?.profile?.realm_access?.roles || []).includes(process.env.REACT_APP_KEYCLOAK_GENERAL_ACCESS_ROLE)
    ) {
      auth.signoutSilent();
      return <Navigate to="/login?message=access_permission_required" replace />;
    }

    return (
      <>
        <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
      </>
    );
  } else {
    return <Navigate to="/login" replace />;
  }
}
